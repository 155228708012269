import React from 'react';
import Page from './Page';

export default function hakkalJammu271122() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Hakkal, Jammu 27/11/2022</heading-two>
                    	<img src="activities/hakkal_jammu-27-11-2022/1-1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/2-2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/3-3.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/9.jpg" style="width: 32%;" alt="">
                    	<img src="activities/hakkal_jammu-27-11-2022/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/12.jpg" style="width: 32%;" alt="">

                        <img src="activities/hakkal_jammu-27-11-2022/13.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/14.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/15.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/16.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/17.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/18.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/19.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/20.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/21.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/22.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/23.jpg" style="width: 32%;" alt="">

                        
                    </div>`,
				}}
			></div>
	</Page>;
}
