import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { AppContext } from 'App';
import clsx from 'clsx';
import { OrgName, OrgNameHi } from 'config';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			fontSize: '3vw',
			textAlign: 'center',
			padding: '0.4em 0px 0.8em',
			color: theme.palette.primary.main,
			marginTop: '-2.5em',
			fontWeight: 'bold',
			filter: 'drop-shadow(0.1em 0.1em 0.03em lightgrey)',
			textTransform: 'uppercase',
			whiteSpace: 'nowrap',

			'&::after, &::before': {
				content: '""',
				display: 'inline-block',
				backgroundImage: `url(${'icons/main-flower.png'})`,
				width: '1.8em',
				height: '1.2em',
				backgroundSize: 'contain',
				margin: '0 0.7em',
				verticalAlign: 'middle',
			},

			'&::after': {
				transform: 'scaleX(-1)',
			},

			[theme.breakpoints.up('xl')]: {
				fontSize: '2.6vw',
				marginTop: '-2.8em',
				paddingBottom: '1em',
			},
			[theme.breakpoints.only('md')]: {
				marginTop: '-2.4em',
				paddingBottom: '0.8em',
				// fontSize: '4vw',
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: '-2.2em',
				paddingBottom: '0.5em',
			},
		},

		title: {
			wordSpacing: 0,
			display: 'inline',
			fontSize: 'inherit',
			fontWeight: 'inherit',
		},
	})
);

const Component = (props) => {
	let classes = useStyles();

	return (
		<div className={classes.root}>
			<AppContext.Consumer>
				{(context) =>
					context.lang === 'hi' ? (
						<h1 className={clsx(classes.title, 'locale-hi')}>{OrgNameHi}</h1>
					) : (
						<h1 className={clsx(classes.title, 'locale-en')}>{OrgName}</h1>
					)
				}
			</AppContext.Consumer>
		</div>
	);
};

export const OrgTitle = Component;
