import { Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { OrgName } from 'config';
import { Icons } from 'config/icons';
import React, { useState } from 'react';
import AppDrawer from './AppDrawer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(1),
		},
		title: {
			flexGrow: 1,
			fontSize: '1.75rem',
		},
	})
);

function CustomAppBar(props) {
	const classes = useStyles();

	const [isDrawerOpen, setDrawerOpen] = useState(false);

	const onClickMenu = () => {
		setDrawerOpen(true);
	};

	return (
		<div className={classes.grow}>
			<AppBar position='fixed'>
				<Toolbar>
					<IconButton onClick={onClickMenu} edge='start' className={classes.menuButton} color='inherit'>
						{Icons.Menu}
					</IconButton>

					<Typography className={classes.title} variant='h6' noWrap>
						{OrgName}
					</Typography>
				</Toolbar>
			</AppBar>
			<Toolbar />
			<AppDrawer isDrawerOpen={isDrawerOpen} setDrawerOpen={setDrawerOpen} />
		</div>
	);
}

export default CustomAppBar;
