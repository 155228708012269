import { grey } from '@material-ui/core/colors';
import WebAppBar from 'components/shell/WebAppBar';
import React from 'react';
import { Border } from './Border';
import { Footer } from './Footer';
import { OrgTitle } from './OrgTitle';

export const WebPage = (props) => {
	return (
		<div style={{ background: props.bgcolor ?? grey[100] }}>
			<WebAppBar />
			<div style={{ position: 'relative', padding: '2em 9em' }}>
				<Border />
				<OrgTitle />
				{props.children}
			</div>
			<Footer />
		</div>
	);
};
