import { Theme, withStyles } from '@material-ui/core';
import React from 'react';
import { BannerRhymeCtrl } from './BannerRhyme';
import { SSDNCtrl } from './SSDNCtrl';

const brandBoxStyles = (theme: Theme) => ({
	brandBox: {
		maxWidth: '60em',
		margin: '0 auto',
		[theme.breakpoints.up('xl')]: {
			marginTop: '-20em',
			marginBottom: '-20em',
		},
		[theme.breakpoints.only('lg')]: {
			marginTop: '-18em',
			marginBottom: '-18em',
		},
		[theme.breakpoints.only('md')]: {
			marginTop: '-15em',
			marginBottom: '-15em',
		},
		[theme.breakpoints.only('sm')]: {
			marginTop: '-15em',
			marginBottom: '-15em',
			width: '90%',
		},
		[theme.breakpoints.down('xs')]: {
			marginTop: '-10em',
			marginBottom: '-10em',
			width: '80%',
		},
	},
	glowImg: {
		display: 'block',
		width: '100%',
		'animation-name': 'sphamadjust-hue',
		'animation-duration': '40000ms',
		'animation-iteration-count': 'infinite',
		'animation-timing-function': 'linear',
	},
	brandImg: {
		display: 'block',
		width: '100%',
		transform: 'rotate(0)',
		marginTop: '-100%',
	},
});

class BrandBoxComponent extends React.Component<{ classes?: any; glowImgSrc: string; brandImgSrc: string }> {
	render() {
		let { classes } = this.props;

		return (
			<div className={classes.brandBox}>
				<img className={classes.glowImg} src={this.props.glowImgSrc} />
				<img className={classes.brandImg} src={this.props.brandImgSrc} />
			</div>
		);
	}
}

const BrandBox = withStyles(brandBoxStyles)(BrandBoxComponent);

const bannerStyles = (theme: Theme) => ({
	background: {
		position: 'initial !important' as 'initial',
		backgroundImage: `url(${'bg/new-banner-double.jpg'})`,
		backgroundSize: '100% 100%',
		overflow: 'hidden',
		marginTop: -2,
		[theme.breakpoints.up('xl')]: {
			fontSize: 17,
		},
		[theme.breakpoints.only('lg')]: {
			fontSize: 15,
		},
		[theme.breakpoints.only('md')]: {
			fontSize: 14,
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: 13,
		},
	},
});

class Component extends React.Component<{ classes?: any }> {
	render() {
		let { classes } = this.props;

		return (
			<div className={classes.background}>
				<SSDNCtrl />
				<BrandBox brandImgSrc={'logo.png'} glowImgSrc={'glow.png'} />
				<BannerRhymeCtrl />
			</div>
		);
	}
}

export const Banner = withStyles(bannerStyles)(Component);
