import React from 'react';
import Page from './Page';

export default function hakkalJammu() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Hakkal, Jammu 26/12/2021</heading-two>
                    	<img src="activities/hakkal-jammu/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/9.jpg" style="width: 32%;" alt="">
                    	<img src="activities/hakkal-jammu/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/1.jpg" style="width: 32%;" alt="">
                    	<img src="activities/hakkal-jammu/12.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/13.jpg" style="width: 32%;" alt="">
                    </div>`,
				}}
			></div>
	</Page>;
}
