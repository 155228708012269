import React from 'react';
import Page from './Page';

export default function Rehari() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Rehari 19/12/2021</heading-two>
                    	<img src="activities/rehari/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/9.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/12.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/13.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/14.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/15.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/16.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/17.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/18.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/19.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/20.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/21.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/22.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/23.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/24.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/25.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/26.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/27.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/28.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/29.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/30.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/31.jpg" style="width: 32%;" alt="">
                       
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-1.mp4" type="video/mp4">
                                <source src="activities/rehari/v-1.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-2.mp4" type="video/mp4">
                                <source src="activities/rehari/v-2.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-3.mp4" type="video/mp4">
                                <source src="activities/rehari/v-3.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-4.mp4" type="video/mp4">
                                <source src="activities/rehari/v-4.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-5.mp4" type="video/mp4">
                                <source src="activities/rehari/v-5.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-6.mp4" type="video/mp4">
                                <source src="activities/rehari/v-6.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-7.mp4" type="video/mp4">
                                <source src="activities/rehari/v-7.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-20.mp4" type="video/mp4">
                                <source src="activities/rehari/v-20.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-8.mp4" type="video/mp4">
                                <source src="activities/rehari/v-8.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-9.mp4" type="video/mp4">
                                <source src="activities/rehari/v-9.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-10.mp4" type="video/mp4">
                                <source src="activities/rehari/v-10.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-11.mp4" type="video/mp4">
                                <source src="activities/rehari/v-11.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-12.mp4" type="video/mp4">
                                <source src="activities/rehari/v-12.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-13.mp4" type="video/mp4">
                                <source src="activities/rehari/v-13.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-14.mp4" type="video/mp4">
                                <source src="activities/rehari/v-14.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-15.mp4" type="video/mp4">
                                <source src="activities/rehari/v-15.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-16.mp4" type="video/mp4">
                                <source src="activities/rehari/v-16.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-17.mp4" type="video/mp4">
                                <source src="activities/rehari/v-17.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-18.mp4" type="video/mp4">
                                <source src="activities/rehari/v-18.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/rehari/v-19.mp4" type="video/mp4">
                                <source src="activities/rehari/v-19.ogg" type="video/ogg">
                        </video>
                        
                    </div>`,
				}}
			></div>
	</Page>;
}
