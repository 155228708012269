import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import { WebPage } from 'components/website/WebPage';
import { isMobile } from 'helpers';
import React from 'react';

const styles = (theme) => ({
	root: (props) => ({
		padding: '1px 16px 16px',
		backgroundColor: props.dark ? grey[200] : 'white',

		minHeight: !props.fullscreen ? 'calc(100% - 57px)' : 'auto',
		height: props.fullscreen ? 'calc(100% - 57px)' : 'auto',
	}),
});

interface PageProps {
	classes: any;
	children: React.ReactNode;
}

class Page extends React.Component<PageProps> {
	render() {
		if (isMobile()) {
			return <div className={this.props.classes.root}>{this.props.children}</div>;
		} else {
			return <WebPage>{this.props.children}</WebPage>;
		}
	}
}

export default withStyles(styles)(Page);
