import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { SSDN } from 'config';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		ssdn: {
			fontSize: '1.65em',
			marginBottom: '0.4em',
			paddingBottom: '0.7em',
			paddingTop: '0.85em',
			textAlign: 'center' as 'center',
			color: 'white', //theme.palette.primary.main,
			width: '80%',
			marginLeft: 'auto',
			marginRight: 'auto',
			textShadow: '0.1em 0.1em 0.2em blue',
			transform: 'rotate(0)',
			fontWeight: 'bold' as 'bold',
			zIndex: 5,

			'&::before, &::after': {
				display: 'inline-block',
				fontSize: '1.4em',
				content: '"❁"',
				padding: '0 0.5em',
				fontWeight: 'normal' as 'normal',
			},
		},
	})
);

export const SSDNCtrl = () => {
	let classes = useStyles();

	return <div className={clsx(classes.ssdn, 'locale-hi')}>{SSDN}</div>;
};
