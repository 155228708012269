import React from 'react';
import Page from './Page';

export default function ActivitiesPage() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">

					<a href="/hakkal-jammu-27-11-22" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">27/11/2022 Hakkal, Jammu</heading-two></a>
                    	<img src="activities/hakkal_jammu-27-11-2022/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal_jammu-27-11-2022/3.jpg" style="width: 32%;" alt="">
                    <a href="/hakkal-jammu-27-11-22" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>


					<a href="/bari-brahmana" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">05/06/2022 Bari Brahmana, Samba Jammu</heading-two></a>
                    	<img src="activities/bari-brahmana-05-06-2022/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/3.jpg" style="width: 32%;" alt="">
                    <a href="/bari-brahmana" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/hakkal-jammu-27-03-22" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">27/03/2022 Hakkal, Jammu</heading-two></a>
                    	<img src="activities/hakkal-jammu-27-03-22/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/3.jpg" style="width: 32%;" alt="">
                    <a href="/hakkal-jammu-27-03-22" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/r-s-pura-jammu" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">27/02/2022 R.S Pura, Jammu </heading-two></a>
                    	<img src="activities/r-s-pura-jammu/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/3.jpg" style="width: 32%;" alt="">
                    <a href="/r-s-pura-jammu" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/nawshera-bhawan" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">24/02/2022 Nawshera Bhawan, Jammu </heading-two></a>
                    	<img src="activities/nawshera-bhawan/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/3.jpg" style="width: 32%;" alt="">
                    <a href="/nawshera-bhawan" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					
					<a href="/hakkal-jammu" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">26/12/2021 Hakkal, Jammu</heading-two></a>
                    	<img src="activities/hakkal-jammu/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu/3.jpg" style="width: 32%;" alt="">
                    <a href="/hakkal-jammu" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

                    <a href="/medical-camp" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">19/12/2021 Medical Camp, Jammu</heading-two></a>
                    	<img src="activities/medical-camp/1.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/2.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/3.jpeg" style="width: 32%;" alt="">
                    <a href="/medical-camp" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/rehari" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">19/12/2021 Rehari, Jammu </heading-two></a>
                    	<img src="activities/rehari/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/rehari/3.jpg" style="width: 32%;" alt="">
                    <a href="/rehari" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/toph-sherkhani" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">19/12/2021 Toph Sherkhani, Jammu </heading-two></a>
                    	<img src="activities/toph-sherkhani/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/3.jpg" style="width: 32%;" alt="">
                    <a href="/toph-sherkhani" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<a href="/vijaypur" style="text-decoration: none;"><heading-two style="font-size: 1.5em;padding: 0px;">19/12/2021 Vijaypur, Jammu </heading-two></a>
                    	<img src="activities/vijaypur/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/3.jpg" style="width: 32%;" alt="">
                    <a href="/vijaypur" style="text-decoration: none;"><heading-two style="padding: 0px;font-size: 14px;">View more</heading-two></a>

					<heading-two style="font-size: 1.5em;padding: 0px;">Dispensary Clip</heading-two>
						<video style="width: 100%;" height="240" controls>
						<source src="activities/dispensary/v-1.mp4" type="video/mp4">
						<source src="activities/dispensary/v-1.ogg" type="video/ogg">
						</video>
                    </div>`,
				}}
			></div>
	</Page>;
}
