import React from 'react';
import Page from './Page';

export default function tophSherkhani() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Toph Sherkhani 19/12/2021</heading-two>
                    	<img src="activities/toph-sherkhani/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/9.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/toph-sherkhani/12.jpg" style="width: 32%;" alt="">
                       
                       
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-1.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-1.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-2.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-2.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-3.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-3.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-4.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-4.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-5.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-5.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-6.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-6.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-7.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-7.ogg" type="video/ogg">
                        </video>
                       
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-8.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-8.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-9.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-9.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-10.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-10.ogg" type="video/ogg">
                        </video>
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/toph-sherkhani/v-11.mp4" type="video/mp4">
                                <source src="activities/toph-sherkhani/v-11.ogg" type="video/ogg">
                        </video>
                                                
                    </div>`,
				}}
			></div>
	</Page>;
}
