import { createStyles, makeStyles, Theme } from '@material-ui/core';
import TextContent from 'components/TextContent';
import { MainRhyme } from 'config';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		rhymes: {
			[theme.breakpoints.up('xl')]: {
				'& > .locale-hi': {
					fontSize: '2.9vw',

					'& .rhyme-line-justified': {
						width: '55%',
					},
				},
			},
			[theme.breakpoints.only('lg')]: {
				'& > .locale-hi': {
					fontSize: '3.1vw',

					'& .rhyme-line-justified': {
						width: '60%',
					},
				},
			},
			[theme.breakpoints.only('md')]: {
				'& > .locale-hi': {
					fontSize: '3.6vw',

					'& .rhyme-line-justified': {
						width: '70%',
					},
				},
			},
			[theme.breakpoints.down('sm')]: {
				'& > .locale-hi': {
					fontSize: '4.1vw',

					'& .rhyme-line-justified': {
						width: '80%',
					},
				},
			},

			color: 'white',
			textAlign: 'center' as 'center',
			padding: '2em 0em 0.5em 0',
			textShadow: '0.1em 0.1em 0.2em blue',
			lineHeight: 1,
			fontWeight: 'bold' as 'bold',

			'& .rhyme-line': {
				'&.rhyme-line-justified': {
					marginBottom: '-0.6em',
				},
			},

			'& .locale-hi': {
				color: 'white',
			},
		},
	})
);

export const BannerRhymeCtrl = () => {
	let classes = useStyles();
	return (
		<div className={classes.rhymes}>
			<TextContent locale='hi' alignRhymes={true}>
				<span dangerouslySetInnerHTML={{ __html: MainRhyme }} />
			</TextContent>
		</div>
	);
};
