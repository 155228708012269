import React from 'react';
import Page from './Page';

export default function Baribrahmana050622() {
	return <Page>
		<div
		dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Bari Brahmana, Samba Jammu 05/06/2022</heading-two>
                    	<img src="activities/bari-brahmana-05-06-2022/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/6.jpg" style="width: 32%;" alt="">
                        
                        <img src="activities/bari-brahmana-05-06-2022/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/9.jpg" style="width: 32%;" alt="">
                    	<img src="activities/bari-brahmana-05-06-2022/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/12.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/13.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/21.jpg" style="width: 32%;" alt="">
                        
                        <img src="activities/bari-brahmana-05-06-2022/17.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/18.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/19.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/20.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/14.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/22.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/23.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/24.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/25.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/26.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/27.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/28.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/29.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/30.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/31.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/15.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/16.jpg" style="width: 32%;" alt="">
                        <img src="activities/bari-brahmana-05-06-2022/7.jpg" style="width: 32%;" alt="">

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/bari-brahmana-05-06-2022/v1.mp4" type="video/mp4">
                                <source src="activities/bari-brahmana-05-06-2022/v1.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/bari-brahmana-05-06-2022/v2.mp4" type="video/mp4">
                                <source src="activities/bari-brahmana-05-06-2022/v2.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/bari-brahmana-05-06-2022/v3.mp4" type="video/mp4">
                                <source src="activities/bari-brahmana-05-06-2022/v3.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/bari-brahmana-05-06-2022/v4.mp4" type="video/mp4">
                                <source src="activities/bari-brahmana-05-06-2022/v4.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/bari-brahmana-05-06-2022/v5.mp4" type="video/mp4">
                                <source src="activities/bari-brahmana-05-06-2022/v5.ogg" type="video/ogg">
                        </video>

                    </div>`,
				}}
			></div>
	</Page>;
}
