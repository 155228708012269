import React from 'react';
import Page from './Page';

export default function nawsheraBhawan() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Nawshera Bhawan 24/02/2022</heading-two>
                    	<img src="activities/nawshera-bhawan/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/9.jpg" style="width: 32%;" alt="">
                    	<img src="activities/nawshera-bhawan/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/11.jpg" style="width: 32%;" alt="">
                    	<img src="activities/nawshera-bhawan/12.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/13.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/14.jpg" style="width: 32%;" alt="">
                        <img src="activities/nawshera-bhawan/15.jpg" style="width: 32%;" alt="">
                    </div>`,
				}}
			></div>
	</Page>;
}
