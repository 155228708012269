import { grey } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import './fonts.css';

let theme = createMuiTheme({
	palette: {
		primary: {
			main: '#32b78d',
			contrastText: '#ffffff',
		},
	},
	typography: {
		htmlFontSize: 10,
		fontFamily: 'Roboto',
		body1: {
			fontSize: '1.7rem',
		},
		body2: {
			letterSpacing: 0,
			fontSize: '1.5rem',
		},
	},
});

let commonTheme = {
	...theme,
	overrides: {
		MuiBackdrop: {
			root: {
				top: 0,
			},
		},
		MuiPaper: {
			root: {
				color: 'rgba(0, 0, 0, 0.65)',
			},
		},
		MuiButton: {
			root: {
				color: 'rgba(0, 0, 0, 0.6)',
				'&:hover': {
					[theme.breakpoints.down('xs')]: {
						backgroundColor: 'inherit',
					},
				},
			},
		},
		MuiCssBaseline: {
			'@global': {
				body: {
					color: 'rgba(0, 0, 0, 0.75)',
					backgroundColor: grey[200],
				},

				'heading-one, heading-two, heading-three': {
					display: 'block',
					color: theme.palette.primary.main,
					fontSize: '1.1em',
					fontWeight: 'bold',
					textAlign: 'center',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',
					marginBottom: '1.5em',
					marginTop: '1em',
				},

				'heading-one': {
					backgroundImage: `url(${'bg/heading-bg.png'})`,
					padding: '2em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5.2vw',
						padding: '1.5em 0',
						marginBottom: '1em',
						marginTop: '1em',
					},
				},

				'heading-two': {
					backgroundImage: `url(${'bg/the-spiritual-master.png'})`,
					padding: '4em 0 0.45em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5.2vw',
						paddingBottom: '1.15em',
						marginBottom: 0,
						marginTop: 0,
					},
				},

				'heading-three': {
					backgroundImage: `url(${'bg/holy-teachings.png'})`,
					padding: '5em 0 0.6em 0',

					[theme.breakpoints.down('xs')]: {
						fontSize: '5vw',
						padding: '3.5em 0 0.2em 0',
						marginBottom: '1em',
						marginTop: 0,
					},
				},

				'.end-content': {
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundPosition: 'center',

					width: '20vw',
					height: '3.5vw',
					maxWidth: 350,
					maxHeight: 80,
					margin: '0 auto',
					backgroundImage: `url(${'bg/end-content.png'})`,
				},

				'.locale-en': {
					'& heading-one, & heading-two, & heading-three': {
						textTransform: 'uppercase',
						// letterSpacing: 0,
						whiteSpace: 'nowrap',
						fontSize: '1em',
					},

					'& heading-one': {
						[theme.breakpoints.down('xs')]: {
							fontSize: '4.5vw',
							padding: '1.8em 0',
						},
					},

					'& haeding-two': {
						[theme.breakpoints.down('xs')]: {
							fontSize: '4.5vw',
							paddingBottom: '0.9em',
						},
					},
				},

				'.content-inline-rhymes': {
					display: 'block',
					textAlign: 'center' as 'center',
					margin: '0 auto',
					marginBottom: '-0.8em',

					'& rhyme-line, & .rhyme-line': {
						display: 'inline-block',
						lineHeight: 1,

						'&.rhyme-line-justified': {
							display: 'block',
							textAlign: 'justify',
							margin: '0 auto',

							'&::after': {
								content: "''",
								display: 'inline-block',
								width: '100%',
							},

							'&::first-of-type': {
								marginTop: '0.6em',
							},
						},
					},

					'& rhyme-type': {
						display: 'block',
					},

					'& rhyme-author, & .rhyme-author': {
						display: 'inline-block',
						textAlign: 'right',
						width: '100%',
						marginTop: '-0.7em',
					},

					'&.locale-hi': {
						fontSize: '0.9em',
						fontWeight: 'bold',
					},
				},
			},
		},
	},
};

export const enUsTheme = commonTheme;
export const hiTheme = commonTheme;
