import CloseIcon from '@material-ui/icons/Close';
import EcoIcon from '@material-ui/icons/Eco';
import HomeIcon from '@material-ui/icons/Home';
import InfoIcon from '@material-ui/icons/Info';
import LanguageIcon from '@material-ui/icons/Language';
import MenuIcon from '@material-ui/icons/Menu';
import PolicyIcon from '@material-ui/icons/Policy';
import React from 'react';

export const Icons = {
	Menu: <MenuIcon />,
	Policy: <PolicyIcon />,
	Home: <HomeIcon />,
	About: <InfoIcon />,
	Donation: <EcoIcon />,
	Close: <CloseIcon />,
	Lang: <LanguageIcon />,
};
