import { createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		border: {
			position: 'absolute',
			top: 0,
			display: 'flex',
			flexDirection: 'column',
			flexFlow: 'column',
			width: '4em',
			height: '100%',
		},
		leftBorder: {
			left: 0,
		},
		rightBorder: {
			right: 0,
		},
		top: {
			flex: '0 1 auto',
		},
		middle: {
			flex: '1 1 auto',
		},
		bottom: {
			flex: '0 1 auto',
		},
	})
);

export const Border = () => {
	let classes = useStyles();

	let border = () => {
		return (
			<React.Fragment>
				<img className={classes.top} src={'icons/PILLAR_TOP.png'} alt='' />
				<img className={classes.middle} src={'icons/PILLAR_MIDDLE.png'} alt='' />
				<img className={classes.bottom} src={'icons/PILLAR_BOTTOM.png'} alt='' />
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<div className={clsx(classes.border, classes.leftBorder)}>{border()}</div>
			<div className={clsx(classes.border, classes.rightBorder)}>{border()}</div>
		</React.Fragment>
	);
};
