import { Typography } from '@material-ui/core';
import { Banner } from 'components/website/Banner';
import { isMobile } from 'helpers';
import React from 'react';
import Page from './Page';

export default function HomePage(props) {
	let lang = props.lang;

	return (
		<>
			{isMobile() === false ? <Banner /> : null}
			<Page>
				{lang === 'en' ? (
					<>
						{React.createElement(
							'heading-one',
							{ class: 'locale-en', style: { fontSize: '1.5em' } },
							'SHRI PARAMHANS ADVAIT MAT'
						)}
						<Typography className='locale-en' style={{ textAlign: 'justify', fontSize: '1.5em' }}>
							The Eternal Beacon Light of Spiritualism and Devotion, the Most-Exalted, the Illustrious Progenitor, 
							His Holiness{' '}
							<i> Shri Shri 108 Shri Swami Advait Anand Ji Maharaj</i> established 
							<i> Shri Paramhans Advait Mat </i> for the upliftment of human kind.
						</Typography>
					</>
				) : (
					<>
						{React.createElement(
							'heading-one',
							{ class: 'locale-hi', style: { fontSize: '1.5em' } },
							'श्री परमहंस अद्वैत मत'
						)}
						<Typography className='locale-hi' style={{ textAlign: 'justify', fontSize: '1.5em' }}>
							श्री परमहंस अद्वैत मत की स्थापना भक्ति-परमार्थ के शाश्वत ज्योतिस्स्तम्भ, परम योगीश्वर, जगत्
							वन्दनीय, प्रात:स्मरणीय श्री श्री 108 श्री स्वामी अद्वैत आनन्द जी महाराज श्री प्रथम पादशाही
							जी ने की। आपके शुभ नाम पर ही यह मत ‘श्री परमहंस अद्वैत मत’ के नाम से विख्यात हुआ।
						</Typography>
					</>
				)}
				{/* <div style={{ marginTop: 32, marginBottom: 32 }} className='end-content' /> */}
			</Page>
		</>
	);
}
