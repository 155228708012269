import React from 'react';
import Page from './Page';

export default function medicalcamp() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Medical Camp</heading-two>
                    	<img src="activities/medical-camp/1.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/2.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/3.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/4.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/5.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/6.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/7.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/8.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/9.jpeg" style="width: 32%;" alt="">
                    	<img src="activities/medical-camp/10.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/11.jpeg" style="width: 32%;" alt="">
                    	<img src="activities/medical-camp/12.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/13.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/14.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/15.jpeg" style="width: 32%;" alt="">
                        <img src="activities/medical-camp/16.jpeg" style="width: 32%;" alt="">
                    </div>`,
				}}
			></div>
	</Page>;
}
