import { Divider } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppContext } from 'App';
import { Icons } from 'config/icons';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
		},
		drawer: {
			[theme.breakpoints.up('sm')]: {
				width: drawerWidth,
				flexShrink: 0,
			},
		},
		drawerPaper: {
			width: drawerWidth,
			overflowX: 'hidden',
			overflowY: 'hidden',
		},
		banner: {
			width: `calc(100% + 1px)`,
		},
	})
);

function ListRouter() {
	return (
		<React.Fragment>
			<List>
				<li>
					<ListItem button to='/' component={RouterLink}>
						<ListItemIcon>{Icons.Home}</ListItemIcon>
						<ListItemText primary='Home' />
					</ListItem>
				</li>
				<li>
					<ListItem button to='/activities' component={RouterLink}>
						<ListItemIcon>{Icons.About}</ListItemIcon>
						<ListItemText primary='Activities' />
					</ListItem>
				</li>
				<li>
					<ListItem button to='/aboutus' component={RouterLink}>
						<ListItemIcon>{Icons.About}</ListItemIcon>
						<ListItemText primary='About Us' />
					</ListItem>
				</li>
				{/* <li>
					<ListItem
						button
						target='_blank'
						to={{ pathname: 'https://donations.shrianandpursatsangtrust.org/shri-anandpur-satsang-trust/donation' }}
						component={RouterLink}
					>
						<ListItemIcon>{Icons.Donation}</ListItemIcon>
						<ListItemText primary='Donation' />
					</ListItem>
				</li> */}
				<Divider />
				<AppContext.Consumer>
					{(context) => (
						<li>
							<ListItem
								button
								onClick={() => {
									context.setLang(context.lang === 'en' ? 'hi' : 'en');
								}}
							>
								<ListItemIcon>{Icons.Lang}</ListItemIcon>
								<ListItemText primary={context.lang === 'en' ? 'Read In Hindi' : 'Read In English'} />
							</ListItem>
						</li>
					)}
				</AppContext.Consumer>
				<Divider />
				<li>
					<ListItem button to='/privacy' component={RouterLink}>
						<ListItemIcon>{Icons.Policy}</ListItemIcon>
						<ListItemText primary='Privacy Policy' />
					</ListItem>
				</li>
				<li>
					<ListItem button to='/terms' component={RouterLink}>
						<ListItemIcon>{Icons.Policy}</ListItemIcon>
						<ListItemText primary='Terms of Use' />
					</ListItem>
				</li>
			</List>
		</React.Fragment>
	);
}

interface ResponsiveDrawerProps {
	isDrawerOpen: boolean;
	setDrawerOpen: (value: boolean) => void;
}

function ResponsiveDrawer(props: ResponsiveDrawerProps) {
	const { isDrawerOpen, setDrawerOpen } = props;
	const classes = useStyles();

	const onClose = (e) => {
		setDrawerOpen(false);
	};

	return (
		<div className={classes.root}>
			<nav className={classes.drawer}>
				<Drawer
					variant='temporary'
					open={isDrawerOpen}
					onClose={onClose}
					classes={{
						paper: classes.drawerPaper,
					}}
					ModalProps={{
						keepMounted: true,
					}}
				>
					<img className={classes.banner} src={'bg/js3.jpeg'} alt='' />
					<div role='presentation' onClick={onClose} onKeyDown={onClose} style={{ overflowY: 'auto' }}>
						<ListRouter />
					</div>
				</Drawer>
			</nav>
		</div>
	);
}

export default ResponsiveDrawer;
