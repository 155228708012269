import AboutUsPage from 'components/pages/AboutUsPage';
import ActivitiesPage from 'components/pages/ActivitiesPage';
import MedicalCamp from 'components/pages/MedicalCamp';
import HakkalJammu from 'components/pages/HakkalJammu';
import HakkalJammu270322 from 'components/pages/HakkalJammu270322';
import HakkalJammu271122 from 'components/pages/HakkalJammu271122';
import Baribrahmana050622 from 'components/pages/Baribrahmana050622';
import RSPuraJammu from 'components/pages/RSPuraJammu';
import TophSherkhani from 'components/pages/TophSherkhani';
import Vijaypur from 'components/pages/Vijaypur';
import Rehari from 'components/pages/Rehari';
import NawsheraBhawan from 'components/pages/NawsheraBhawan';
import PrivacyPage from 'components/pages/PrivacyPage';
import TermsPage from 'components/pages/TermsPage';
import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch } from 'react-router-dom';
import HomePage from '../pages/HomePage';

interface PageRouterProps {
	location: any;
	lang: string;
	setLang: (lang: string) => void;
}

class PageRouter extends React.Component<PageRouterProps> {
	render() {
		const { location } = this.props;

		return (
			<Switch location={location}>
				<Route exact path='/'>
					<HomePage lang={this.props.lang} setLang={this.props.setLang} />
				</Route>
				<Route exact path='/activities'>
					<ActivitiesPage />
				</Route>
				<Route exact path='/aboutus'>
					<AboutUsPage />
				</Route>
				<Route exact path='/vijaypur'>
					<Vijaypur />
				</Route>

				<Route exact path='/toph-sherkhani'>
					<TophSherkhani />
				</Route>
				<Route exact path='/r-s-pura-jammu'>
					<RSPuraJammu />
				</Route>
				<Route exact path='/rehari'>
					<Rehari />
				</Route>
				<Route exact path='/nawshera-bhawan'>
					<NawsheraBhawan />
				</Route>
				<Route exact path='/medical-camp'>
					<MedicalCamp />
				</Route>
				<Route exact path='/hakkal-jammu'>
					<HakkalJammu />
				</Route>
				<Route exact path='/hakkal-jammu-27-03-22'>
					<HakkalJammu270322 />
				</Route>
				<Route exact path='/hakkal-jammu-27-11-22'>
					<HakkalJammu271122 />
				</Route>
				<Route exact path='/bari-brahmana'>
					<Baribrahmana050622 />
				</Route>
				<Route exact path='/privacy'>
					<PrivacyPage />
				</Route>
				<Route exact path='/terms'>
					<TermsPage />
				</Route>
				<Route>
					<HomePage lang={this.props.lang} setLang={this.props.setLang} />
				</Route>
			</Switch>
		);
	}
}

export default withRouter(PageRouter);
