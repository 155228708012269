import { Button, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { BJK, COPYRIGHT, OrgName } from 'config';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			textAlign: 'center',
			backgroundColor: theme.palette.primary.main,
			color: 'white',
			boxShadow: '0px -10px 6px -6px #777, 0px 0px 6px 2px #d3d3d3',
		},
		row: {
			margin: '0 30px',
			padding: '0.3rem 0',
		},
		firstRow: {
			borderBottom: '1px solid white',
			padding: '0.8em 0 0.5em',
			opacity: 0.9,
			fontSize: '1.8rem',
		},
		btns: {
			textAlign: 'right',
		},
		link: {
			color: 'white',
			fontSize: '1.3rem',
		},
		copyright: {
			fontSize: '1.3rem',
			float: 'left',
			padding: '0.7rem 0',
			opacity: 0.8,
		},
	})
);

export const Footer = () => {
	let classes = useStyles();

	var bjk = <Typography className={clsx(classes.firstRow, 'locale-hi')}>{BJK}</Typography>;
	var copyright = <Typography className={classes.copyright}>{COPYRIGHT + ' - ' + OrgName}</Typography>;

	var firstRow = <div className={classes.row}>{bjk}</div>;

	var secondRow = (
		<div className={classes.row}>
			{copyright}
			<div className={classes.btns}>
				<Button className={classes.link} color='inherit' to='/privacy' component={Link}>
					Privacy Policy
				</Button>
				<Button className={classes.link} color='inherit' to='/terms' component={Link}>
					Terms of Use
				</Button>
			</div>
		</div>
	);

	return (
		<div className={classes.root}>
			{firstRow}
			{secondRow}
		</div>
	);
};
