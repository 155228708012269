import { Button } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { AppContext } from 'App';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: 'white',
			transform: 'rotate(0)',
			backgroundImage: `url('icons/top_arch.png')`,
			backgroundSize: '100% 100%',
			height: '20vw',
			filter: 'drop-shadow(5px 5px 5px grey)',
		},
		appbar: {
			backgroundColor: 'transparent',
			boxShadow: 'none',

			'& .MuiButton-label': {
				fontWeight: 'bold',
				textShadow: '2px 2px 5px green',
			},

			'& .MuiIconButton-label': {
				filter: 'drop-shadow(2px 2px 5px green)',
			},
		},
		grow: {
			flexGrow: 1,
		},
		menuButton: {
			marginRight: theme.spacing(1),
			color: 'inherit',
		},
	})
);

function WebAppBar(props) {
	const classes = useStyles();

	return (
		<div className={clsx(classes.grow, classes.root)}>
			<AppBar position='relative' className={classes.appbar}>
				<Toolbar>
					<Button className={classes.menuButton} to='/' component={Link}>
						Home
					</Button>
					<Button className={classes.menuButton} to='/activities' component={Link}>
						Activities
					</Button>
					<Button className={classes.menuButton} to='/aboutus' component={Link}>
						About Us
					</Button>
					{/* <Button
						className={classes.menuButton}
						target='_blank'
						to={{ pathname: 'https://donations.shrianandpursatsangtrust.org/shri-anandpur-satsang-trust/donation' }}
						component={Link}
					>
						Donation
					</Button> */}
					<div className={classes.grow} />
					<AppContext.Consumer>
						{(context) => (
							<Button
								className={classes.menuButton}
								onClick={() => {
									context.setLang(context.lang === 'en' ? 'hi' : 'en');
								}}
							>
								{context.lang === 'en' ? 'Read In Hindi' : 'Read In English'}
							</Button>
						)}
					</AppContext.Consumer>
				</Toolbar>
			</AppBar>
		</div>
	);
}

export default WebAppBar;
