import React from 'react';
import Page from './Page';

export default function rSPuraJammu() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">R.S Pura Jammu 27/02/2022</heading-two>
                    	<img src="activities/r-s-pura-jammu/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/r-s-pura-jammu/8.jpg" style="width: 32%;" alt="">
                        <br>
                        <video width="40%" height="240" controls>
                                <source src="activities/r-s-pura-jammu/v-1.mp4" type="video/mp4">
                                <source src="activities/r-s-pura-jammu/v-1.ogg" type="video/ogg">
                        </video>
                        <video width="40%" height="240" controls>
                                <source src="activities/r-s-pura-jammu/v-2.mp4" type="video/mp4">
                                <source src="activities/r-s-pura-jammu/v-2.ogg" type="video/ogg">
                        </video>
                        
                    </div>`,
				}}
			></div>
	</Page>;
}
