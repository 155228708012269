import React from 'react';
import Page from './Page';

export default function Vijaypur() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Vijaypur 19/12/2021</heading-two>
                    	<img src="activities/vijaypur/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/9.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/12.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/13.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/14.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/15.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/16.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/17.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/18.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/19.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/20.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/21.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/22.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/23.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/24.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/25.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/26.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/27.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/28.jpg" style="width: 32%;" alt="">
                        <img src="activities/vijaypur/29.jpg" style="width: 32%;" alt="">
                                                
                    </div>`,
				}}
			></div>
	</Page>;
}
