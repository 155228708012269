import isMobileLib from 'ismobilejs';

export function isMobile() {
	return isMobileLib.phone || isMobileLib.tablet;
}

export function alignRhymes(el: HTMLElement) {
	if (!el) {
		return;
	}

	let rhymeContainers = el.getElementsByClassName('content-inline-rhymes');

	for (let i = 0; i < rhymeContainers.length; i++) {
		let rhymeContainer: HTMLElement = rhymeContainers[i] as HTMLElement;
		if (rhymeContainer.offsetParent === null) {
			// checks for visibility
			continue;
		}

		rhymeContainer.style.maxWidth = '';
		let rhymes = rhymeContainer.getElementsByTagName('rhyme-line');
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = '';
			rhyme.classList.remove('rhyme-line-justified');
		});

		var maxLength = 0;
		for (var j = 0; j < rhymes.length; j++) {
			var length = rhymes[j].clientWidth;

			if (length > maxLength) {
				maxLength = length;
			}
		}

		// eslint-disable-next-line
		[].forEach.call(rhymes, (el) => {
			let rhyme = el as HTMLElement;
			rhyme.style.width = maxLength + 10 + 'px';
			rhyme.style.textAlign = '';
			rhyme.classList.add('rhyme-line-justified');
		});

		var rhymeAuthor = rhymeContainer.getElementsByTagName('rhyme-author')[0] as HTMLElement;
		if (rhymeAuthor) {
			rhymeAuthor.style.display = 'inline';
			rhymeAuthor.style.width = '';
			var rhymeAuthorWidth = rhymeAuthor.offsetWidth;
			rhymeContainer.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.maxWidth = maxLength + rhymeAuthorWidth + 'px';
			rhymeAuthor.style.display = 'block';
		}
	}
}
