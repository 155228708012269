import { CssBaseline, ThemeProvider } from '@material-ui/core';
import AppBar from 'components/shell/AppBar';
import PageRouter from 'components/shell/PageRouter';
import { enUsTheme, hiTheme } from 'config/theme';
import 'fonts/surekhbold.woff2';
import 'fonts/surekhnormal.woff2';
import { isMobile } from 'helpers';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

export const AppContext = React.createContext({ lang: 'en', setLang: (lang: string) => {} });

function App() {
	const [lang, setLang] = useState('en');

	return (
		<AppContext.Provider value={{ lang, setLang }}>
			<ThemeProvider theme={lang === 'en' ? enUsTheme : hiTheme}>
				<BrowserRouter>
					<div style={{ height: '100%' }}>
						<CssBaseline />
						{isMobile() ? <AppBar /> : null}
						<PageRouter lang={lang} setLang={setLang} />
					</div>
				</BrowserRouter>
			</ThemeProvider>
		</AppContext.Provider>
	);
}

export default App;
