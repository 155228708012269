import React from 'react';
import Page from './Page';

export default function hakkalJammu270322() {
	return <Page>
		<div
				dangerouslySetInnerHTML={{
					__html: `
					<div style="width: 100%;">
                    <heading-two style="font-size: 1.5em;padding: 0px;">Hakkal, Jammu 27/03/2022</heading-two>
                    	<img src="activities/hakkal-jammu-27-03-22/1.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/2.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/3.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/4.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/5.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/6.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/7.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/8.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/9.jpg" style="width: 32%;" alt="">
                    	<img src="activities/hakkal-jammu-27-03-22/10.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/11.jpg" style="width: 32%;" alt="">
                        <img src="activities/hakkal-jammu-27-03-22/12.jpg" style="width: 32%;" alt="">
                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v1.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v1.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v2.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v2.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v3.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v3.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v4.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v4.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v5.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v5.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v6.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v6.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v7.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v7.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v8.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v8.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v9.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v9.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v10.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v10.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v11.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v11.ogg" type="video/ogg">
                        </video>

                        <video style="width: 32%;" height="240" controls>
                                <source src="activities/hakkal-jammu-27-03-22/v12.mp4" type="video/mp4">
                                <source src="activities/hakkal-jammu-27-03-22/v12.ogg" type="video/ogg">
                        </video>
                    </div>`,
				}}
			></div>
	</Page>;
}
